<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="role"
      v-model="role"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="roles"
      :placeholder="t('Chọn loại người dùng')"
      @input="valueChanged"
      @open="onOpen"
    ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ t('Không có dữ liệu') }}
      </template></v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      role: this.defaultValue,
      roles: [],
    };
  },
  created() {
    // this.fetchDatas();
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.role);
    },
    fetchDatas() {
      useJwt.getRoles().then(res => {
        this.roles = res.data.items;
      });
    },
    onOpen() {
      if (this.roles.length === 0) {
        this.fetchDatas();
      }
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
